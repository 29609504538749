import useCRUD from "../hook/useCRUD";
import React, {Fragment, useEffect, useState} from "react";
import Screen from "../screen-element/screen";
import IconAndText from "../components/icon-and-text";
import {Edit, Loader} from "../components/icons";
import Table from "../components/table";
import Pagination from "../components/pagination";
import ErrorModal from "../components/error-modal";
import Row from "../components/row";
import {TableHead} from "../components/table-head";
import AutoTitle from "../components/auto-title";
import Button from "../components/button";
import TextInput from "../components/text-input";
import PasswordInput from "../components/password-input";
import * as Yup from "yup";
import Modal from "../components/modal";
import {Form, Formik} from "formik";
import useBL from "../hook/useBL";
import config from "../config.json";
import {displaySource} from "../functions";

export default function Onlinehandel() {

  const pageSize = 10;

  const crud = useCRUD();
  const bl = useBL();

  const [user, setUser] = useState([]);
  const [totalUser, setTotalUser] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({display: 'Benutzername', orderBy: 'username', order: 'asc'});
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [reloadKey, setReloadKey] = useState(0);
  const [successMessage, setSuccessMessage] = useState(null);

  const validate = Yup.object({
    email: Yup.string().email('Bitte geben Sie eine gültige E-Mail Adresse ein'),
  });

  useEffect(() => {
    setPage(0);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);

    crud.data.bulk.read({
      entity: 'user', page, page_size: pageSize, order_by: order.orderBy, order: order.order,
      filter: filter.length ? crud.filter.and(...filter, crud.filter.equals('is_online_sales', 1)) :
        crud.filter.equals('is_online_sales', 1)
    })
      .then( result => {
        setUser(result.items);
        setTotalUser(result.total);
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        clearTimeout(timeout);
        setShowLoading(false);
        setLoading(false);
      });
  }, [crud, page, order, filter, reloadKey]);

  const submit = async (values, {setSubmitting}) => {

    let items = {...values};

    for (const key in items) {
      if (items[key] && items[key].length > 0) {
        items[key] = items[key].trim();
      }
    }

    let newUserId = null;
    let withoutErrors = true;

    if (items.id) {
      if (items.password?.length > 0) {
        try {
          await bl.sudo_change_password({new_password: items.password, user_id: items.id});
        } catch (error) {
          withoutErrors = false;
          setErrors(error);
        }
      }
      if (!withoutErrors) {
        return;
      }

      let existErrors = [];
      if (items.email) {
        try {
          let check = await crud.data.read({entity: "user", filter: crud.filter.and(
            {property: "email", operator: "equals", value: items.email},
            {property: "id",    operator: "notEquals", value: items.id}
          )});
          if (check) {
            withoutErrors = false;
            existErrors = [...existErrors, {message: 'E-Mail-Adresse bereits vorhanden'}];
          }
        } catch (error) {
          setErrors(error);
          withoutErrors = false;
        }
      }
      if (items.username) {
        try {
          let check = await crud.data.read({entity: "user", filter: crud.filter.and(
            {property: "username", operator: "equals", value: items.username},
            {property: "id",    operator: "notEquals", value: items.id}
          )});
          if (check) {
            withoutErrors = false;
            existErrors = [...existErrors, {message: 'Benutzername bereits vorhanden'}];
          }
        } catch (error) {
          setErrors(error);
          withoutErrors = false;
        }
      }
      if (!items.email && !items.username) {
        setErrors([{message: 'Es muss eine E-Mail-Adresse oder ein Benutzername angegeben werden'}]);
        return;
      }
      if (existErrors.length > 0) {
        setErrors(existErrors);
      }
      if (withoutErrors) {
        delete items.password;
        crud.data.update({entity: 'user', id: items.id, update: {...items, updated_by_source: "admin"}})
          .catch(errors => {
            setErrors(errors);
            withoutErrors = false;
          })
          .finally(() => setSubmitting(false));
      }
    } else {
      if (!items.email && !(items.password && items.username)) {
        setErrors([{message: 'Es muss eine E-Mail-Adresse oder ein Benutzername mit Passwort angegeben werden'}]);
        return;
      }
      await bl.create_user({
        user: {...items, is_credit_excluded: '1', created_by_source: "admin"},
        url: config.SHOP_URL + '/passwort-zuruecksetzen/TOKEN'
      })
        .then(userIdResult => newUserId = userIdResult)
        .catch(errors => {
          setErrors(errors);
          withoutErrors = false;
        });
    }

    if (withoutErrors) {
      setSuccessMessage(newUserId ? 'Erfolgreich erstellt' : 'Erfolgreich geändert');
    }
  };

  const thead = [
    <TableHead property={{name: 'username', title: 'Benutzername'}} sortable filterable/>,
    <TableHead property={{name: 'email', title: 'E-Mail'}} sortable filterable/>,
    <TableHead property={{name: 'first_name', title: 'Vorname'}} sortable filterable/>,
    <TableHead property={{name: 'last_name', title: 'Nachname'}} sortable filterable/>,
    <TableHead resetFilter/>
  ];

  return (
    <Screen className="CustomerPage">
      <h1 className="text-center">Onlinehandel</h1>
      <Row columns={{default: 1, lg: 2}} gap='3'>
        <div>
          <Button text="Neuen Onlinehändler anlegen" onClick={() => setShowEdit(true)} inline/>
        </div>
      </Row>
      <Table headEntries={thead} order={order} setOrder={setOrder} filter={filter} setFilter={setFilter}
        className="online-sales-table">
        {
          loading && showLoading &&
          <tr>
            <td className="col-span">
              <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
            </td>
          </tr>
        }
        {
          !showLoading && totalUser === 0 &&
          <tr>
            <td className="col-span">
              <h2>Keine Einträge vorhanden.</h2>
            </td>
          </tr>
        }
        {
          !showLoading && user.length > 0 &&
          <>
            {user.map((customer, index) =>
              <Fragment key={index}>
                <tr className={(index + 1) % 2 === 0 ? 'shaded' : ''}>
                  <td><AutoTitle>{customer.username}</AutoTitle></td>
                  <td><AutoTitle>{customer.email}</AutoTitle></td>
                  <td><AutoTitle>{customer.first_name}</AutoTitle></td>
                  <td><AutoTitle>{customer.last_name}</AutoTitle></td>
                  <td className="icon-row">
                    <div onClick={() => setShowEdit(customer)} title="bearbeiten">
                      <Edit/>
                    </div>
                  </td>
                </tr>
              </Fragment>
            )}
          </>
        }
      </Table>
      {
        !loading && totalUser > user.length &&
        <Pagination className="mt-2" total={totalUser} page={page} pageSize={pageSize} setPage={setPage}/>
      }
      {showEdit &&
        <Modal className="Onlinehandel" title={showEdit.id ? "Onlinehändler Bearbeiten" : "Onlinehändler Anlegen"}
          onDismiss={() => {
            setShowEdit(null);
            setSuccessMessage(null);
            setReloadKey(reloadKey => reloadKey + 1);
          }}>
          <Formik initialValues={{
            id: showEdit.id || null,
            username: showEdit.username || '',
            email: showEdit.email || '',
            first_name: showEdit.first_name || '',
            last_name: showEdit.last_name || '',
            owner_id : showEdit.owner_id || '0',
            password: '',
            is_sales: '0',
            is_field_sales: '0',
            is_online_sales: '1',
          }} onSubmit={submit} validate={() => setErrors([])} validationSchema={validate}>
            {() => (
              <Form>
                <Row>
                  <Button inverted text="Abbrechen" onClick={() => {
                    setShowEdit(null);
                    setReloadKey(reloadKey => reloadKey + 1);
                  }}/>
                  <Button color="primary" type="submit" text="Speichern"/>
                </Row>
                <Row columns={{default: 1, lg: 2}} gap='1'>
                  <TextInput value={showEdit.username} name="username" label="Benutzername" id="username"/>
                  <TextInput value={showEdit.email} name="email" label="E-Mail" id="email"/>
                  <TextInput value={showEdit.first_name} name="first_name" label="Vorname" id="first_name"/>
                  <TextInput value={showEdit.last_name} name="last_name" label="Nachname" id="last_name"/>
                  <PasswordInput name="password" label="Neues Passwort"/>
                </Row>
                {showEdit.id && <span> Erstellt am: {new Date(showEdit.created_at)
                  .toLocaleString('de-DE', {
                    timeZone: 'Europe/Berlin',
                    hour: '2-digit',
                    minute: '2-digit',
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                  })} Uhr durch {displaySource(showEdit.created_by_source)}</span>}
                {showEdit.id && <div className="mt-025">
                  Zuletzt bearbeitet: {showEdit.updated_at ?  new Date(showEdit.updated_at)
                    .toLocaleString('de-DE', {
                      timeZone: 'Europe/Berlin',
                      hour: '2-digit',
                      minute: '2-digit',
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric'
                    }) + ' Uhr durch ' + displaySource(showEdit.updated_by_source) : "–"}
                </div>}
              </Form>)}
          </Formik>
        </Modal>}
      {
        successMessage?.length > 0 &&
        <Modal className={"SuccessMessage"} title="Speichern" onDismiss={() => {
          setShowEdit(null);
          setSuccessMessage(null);
          setReloadKey(reloadKey => reloadKey + 1);
        }}>
          <h3>{successMessage}</h3>
          <p className="text-right"><Button text="OK" inline onClick={() => {
            setShowEdit(null);
            setSuccessMessage(null);
            setReloadKey(reloadKey => reloadKey + 1);
          }}/></p>
        </Modal>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Screen>
  );
}
