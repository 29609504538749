import useCRUD from "../hook/useCRUD";
import React, {Fragment, useEffect, useState} from "react";
import Screen from "../screen-element/screen";
import IconAndText from "../components/icon-and-text";
import {Edit, Loader} from "../components/icons";
import Table from "../components/table";
import Pagination from "../components/pagination";
import ErrorModal from "../components/error-modal";
import Row from "../components/row";
import {TableHead} from "../components/table-head";
import AutoTitle from "../components/auto-title";
import Button from "../components/button";
import FieldSalesEdit from "../components/field-sales-edit";

export default function Aussendienst() {

  const pageSize = 10;

  const crud = useCRUD();

  const [user, setUser] = useState([]);
  const [totalUser, setTotalUser] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({display: 'Benutzername', orderBy: 'username', order: 'asc'});
  const [filter, setFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [reloadKey, setReloadKey] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);

    crud.data.bulk.read({
      entity: 'user', page, page_size: pageSize, order_by: order.orderBy, order: order.order,
      filter: filter.length ? crud.filter.and(...filter, crud.filter.equals('is_field_sales', 1)) :
        crud.filter.equals('is_field_sales', 1)
    })
      .then( result => {
        setUser(result.items);
        setTotalUser(result.total);
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        clearTimeout(timeout);
        setShowLoading(false);
        setLoading(false);
      });
  }, [crud, page, order, filter, reloadKey]);

  const thead = [
    <TableHead property={{name: 'username', title: 'Benutzername'}} sortable filterable/>,
    <TableHead property={{name: 'email', title: 'E-Mail'}} sortable filterable/>,
    <TableHead property={{name: 'first_name', title: 'Vorname'}} sortable filterable/>,
    <TableHead property={{name: 'last_name', title: 'Nachname'}} sortable filterable/>,
    <TableHead resetFilter/>
  ];

  return (
    <Screen className="CustomerPage">
      <h1 className="text-center">Außendienst</h1>
      <Row columns={{default: 1, lg: 2}} gap='3'>
        <div>
          <Button text="Neuen Außendienstler anlegen" onClick={() => setShowEdit(true)} inline/>
        </div>
      </Row>
      <Table headEntries={thead} order={order} setOrder={setOrder} filter={filter} setFilter={setFilter}
        className="field-sales-table">
        {
          loading && showLoading &&
          <tr>
            <td className="col-span">
              <IconAndText icon={<Loader/>} text="Laden…" align="left"/>
            </td>
          </tr>
        }
        {
          !showLoading && totalUser === 0 &&
          <tr>
            <td className="col-span">
              <h2>Keine Einträge vorhanden.</h2>
            </td>
          </tr>
        }
        {
          !showLoading && user.length > 0 &&
            <>
              {user.map((customer, index) =>
                <Fragment key={index}>
                  <tr className={(index + 1) % 2 === 0 ? 'shaded' : ''}>
                    <td><AutoTitle>{customer.username}</AutoTitle></td>
                    <td><AutoTitle>{customer.email}</AutoTitle></td>
                    <td><AutoTitle>{customer.first_name}</AutoTitle></td>
                    <td><AutoTitle>{customer.last_name}</AutoTitle></td>
                    <td className="icon-row">
                      <div onClick={() => setShowEdit(customer)} title="bearbeiten">
                        <Edit/>
                      </div>
                    </td>
                  </tr>
                </Fragment>
              )}
            </>
        }
      </Table>
      {
        !loading && totalUser > user.length &&
        <Pagination className="mt-2" total={totalUser} page={page} pageSize={pageSize} setPage={setPage}/>
      }
      {showEdit &&
          <FieldSalesEdit title="Außendienst bearbeiten" user={showEdit} typ='is_field_sales'
            onDismiss={() => {
              setShowEdit(null);
              setReloadKey(reloadKey => reloadKey + 1);
            }}
          >
          </FieldSalesEdit>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Screen>
  );
}
