import config from "../config.json";
import {useContext, useMemo} from "react";
import {AuthContext} from "../context/auth-context";

export default function useBL(authToken = null) {

  const authContext = useContext(AuthContext);

  return useMemo(() => {

    const call = (remoteFunction, remoteArguments) => {
      let url = config.BL_URL + '/' + remoteFunction.replaceAll('/', '.');
      return new Promise((resolve, reject) => {
        let body = {
          function: remoteFunction,
          parameters: remoteArguments
        };
        if (authToken || authContext.authToken) {
          body.auth = authToken || authContext.authToken;
        }
        fetch(
          url,
          {
            method: 'POST',
            body: JSON.stringify(body)
          })
          .then(response => {
            if (response.status !== 200) {
              reject([{message: 'Interner Fehler in der Business Logik.'}]);
            } else {
              response.json()
                .then(result => {
                  if ('errors' in result && result.errors.length > 0) {
                    reject(result.errors);
                  } else {
                    resolve(result.result);
                  }
                })
                .catch(() => {
                  return [{message: 'Fehler beim Verarbeiten der Antwort aus der Business Logik.'}];
                });
            }
          })
          .catch(error => {
            console.error(error);
            reject([{
              message: 'Die Verbindung zur Business Logik kann nicht hergestellt werden.'
            }]);
          });
      });
    };

    return {
      login: ({username_or_email, password, be_admin}) => call('login', {username_or_email, password, be_admin}),
      reset_password: ({token, password}) => call('reset_password', {token, password}),
      validate_password_reset_token: ({token}) => call('validate_password_reset_token', {token}),
      ensure_password_complexity: ({password}) => call('ensure_password_complexity', {password}),
      change_password: ({old_password, new_password}) => call('change_password', {old_password, new_password}),
      sudo_change_password: ({new_password, user_id}) => call('sudo_change_password', {new_password, user_id}),
      recover_password:
        ({username_or_email, url, be_admin}) => call('recover_password', {username_or_email, url, be_admin}),
      create_user: ({user, url}) => call('create_user', {user, url}),
      renew_auth_token: () => call('renew_auth_token'),
      create_customer_password: ({customer_id, url}) => call('create_customer_password', {customer_id, url}),
      convert_image: ({image_id, max_resolution}) => call('convert_image', {image_id, max_resolution}),
      list_orders: ({page, page_size, filter, order_by, order}) =>
        call('list_orders', {page, page_size, filter, order_by, order}),
      get_statistics: ({time_unit, data_type, start_date, end_date}) =>
        call('get_statistics', {time_unit, data_type, start_date, end_date}),
      get_total_credit_transactions: ({date_from, date_to} = {}) =>
        call('get_total_credit_transactions', {date_from, date_to})
    };

  }, [authContext.authToken, authToken]);

}
