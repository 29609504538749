import Screen from "../screen-element/screen";
import IconAndText from "../components/icon-and-text";
import {Loader} from "../components/icons";
import ErrorModal from "../components/error-modal";
import {useEffect, useState} from "react";
import useBL from "../hook/useBL";
import LineChart from "../components/line-chart";

export default function Punkte() {

  const bl = useBL();
  const timeUnit = "day";

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [datasets, setDatasets] = useState([]);

  useEffect(() => {
    setLoading(true);
    bl.get_total_credit_transactions()
      .then((result) => {
        let newDatasets = [];
        result.forEach((dataset) => {
          newDatasets.push({
            pointBackgroundColor: "green",
            pointBorderWidth: 2,
            pointRadius: 5,
            pointBorderColor: "white",
            borderColor: "green",
            borderWidth: 1,
            label: "Punkte",
            data: dataset.map(item => {
              return ({x: item["date_validity"], y: item["total_amount"]});
            }),
            yAxisID: "yTotalAmount"
          });
        });
        setDatasets(newDatasets);
      })
      .catch(errors => setErrors(errors))
      .finally(() => setLoading(false));
  }, [bl]);

  return (
    <Screen>
      <h1>Punkte</h1>
      {loading && <IconAndText icon={<Loader/>} text="Laden…" align="left"/>}
      {
        !loading &&
        <>
          <p>Anzahl insgesamt verfügbarer Punkte von heute an bis in 14 Tagen.</p>
          <LineChart datasets={datasets} timeUnit={timeUnit} className="line-chart"/>
        </>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </Screen>
  );
}