import "./admin-edit.scss";
import Modal from "./modal";
import ErrorModal from "./error-modal";
import {useState} from "react";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import TextInput from "./text-input";
import Button from "./button";
import Row from "./row";
import useCRUD from "../hook/useCRUD";
import useClaims from "../hook/useClaims";
import useBL from "../hook/useBL";
import {displaySource} from "../functions";

export default function AdminEdit({admin, onDismiss}) {
  
  const [errors, setErrors] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const crud = useCRUD();
  const claims = useClaims();
  const bl = useBL();

  const validationSchema = Yup.object({
    email: Yup.string().required('Bitte geben Sie eine E-Mail-Adresse ein')
      .email('Bitte eine gültige E-Mail-Adresse eingeben')
  });

  const submit = (values, {setSubmitting}) => {

    for (const key in values) {
      if (values[key] && values[key].length > 0) {
        values[key] = values[key].trim();
      }
    }

    if (values.id) {
      crud.data.read({ entity: "user", filter: {property: "email", operator: "equals", value: values.email}})
        .then((check) => {
          if (!check || check.email === admin.email) {
            crud.data.update({entity: 'user', id: values.id, update: {...values, updated_by_source: "admin"}})
              .then(() => setSuccessMessage('geändert'))
              .catch(errors => setErrors(errors))
              .finally(() => setSubmitting(false));
          } else {
            return setErrors([{message: 'E-Mail-Adresse bereits vorhanden'}]);
          }
        })
        .catch(errors => setErrors(errors))
        .finally(() => setSubmitting(false));
    } else {
      bl.create_user({
        user: {...values, is_credit_excluded: '1', created_by_source: 'admin'},
        url: window.location.origin + '/passwort-zuruecksetzen/TOKEN'
      })
        .then(() => setSuccessMessage('erstellt'))
        .catch(errors => setErrors(errors))
        .finally(() => setSubmitting(false));
    }
  };
  const deleteAdmin = (admin, {setSubmitting}) => {
    if (admin.id !== claims.user_id) {
      crud.data.delete({entity: 'user', id: parseInt(admin.id)})
        .then(() => setSuccessMessage('gelöscht'))
        .catch(errors => setErrors(errors))
        .finally(() => setSubmitting(false));
    } else {
      setErrors([{message: 'Sie können sich nicht selber löschen'}]);
    }
    setShowDeleteConfirm(null);
  };

  return (
    <>
      {
        <Modal className={"AdminEdit"} title={admin.id ? "Shop Admin Bearbeiten" : "Shop Admin Anlegen"}
          onDismiss={onDismiss}>
          <Formik
            initialValues={{
              id: admin.id,
              username: admin.username ? admin.username : '',
              email: admin.email ? admin.email : '',
              first_name: admin.first_name ? admin.first_name : '',
              last_name: admin.last_name ? admin.last_name : '',
              is_admin : admin.is_admin ? admin.is_admin : '1',
              owner_id : admin.owner_id ? admin.owner_id : '0',
            }} validationSchema={validationSchema}
            onSubmit={submit} validate={() => setErrors([])}
          >
            {() => (
              <Form>
                <TextInput name="username" label={"Benutzername"}>{admin.name}</TextInput>
                <TextInput name="email" label={"E-Mail"} required/>
                <TextInput name="first_name" label={"Vorname"}/>
                <TextInput name="last_name" label={"Nachname"}/>
                {admin.id && <span> Erstellt am: {new Date(admin.created_at)
                  .toLocaleString('de-DE', {
                    timeZone: 'Europe/Berlin',
                    hour: '2-digit',
                    minute: '2-digit',
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                  })} Uhr durch {displaySource(admin.created_by_source)}</span>}
                {admin.id && <div className="mt-025">
                  Zuletzt bearbeitet: {admin.updated_at ? new Date(admin.updated_at)
                    .toLocaleString('de-DE', {
                      timeZone: 'Europe/Berlin',
                      hour: '2-digit',
                      minute: '2-digit',
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric'
                    }) + ' Uhr durch ' + displaySource(admin.updated_by_source) : "–"}
                </div>}
                <Row className={admin.id ? "mt-1" : ""}>
                  <Button inverted onClick={onDismiss} text="Abbrechen"/>
                  {admin.id && <Button onClick={() => setShowDeleteConfirm(admin.id)}
                    text="Löschen" className="Danger"/>}
                  <Button type="submit" text="Speichern"/>
                </Row>
              </Form>)}
          </Formik>
          {showDeleteConfirm &&
          <Modal className={"DeleteConfirm"} title="Shop Admin Löschen" onDismiss={() => setShowDeleteConfirm(false)}>
            <h2>Bitte bestätigen Sie das Löschen dieses Shop Admins</h2>
            <Formik initialValues={{id: showDeleteConfirm}} validationSchema={Yup.object({
              id: Yup.string()
                .required('Bitte eine ID eingeben')
            })}
            onSubmit={deleteAdmin} validate={() => setErrors([])}>
              {() => (
                <Form>
                  <Row>
                    <Button inverted onClick={() => setShowDeleteConfirm(false)} text="Abbrechen"/>
                    <Button type="submit" text="Löschen"/>
                  </Row>
                </Form>)}
            </Formik>
          </Modal>}
          {successMessage.length > 0 &&
          <Modal className={"SuccessMessage"} title="Shop Admin geändert"
            onDismiss={onDismiss}>
            <h3>Der Shop Admin wurde {successMessage}</h3>
            <p className="text-right"><Button text="OK" onClick={onDismiss} inline/></p>
          </Modal>}
          <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
        </Modal>
      }
    </>);
}
